/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdDragHandle } from "react-icons/md";
import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
  Button, Fade,
  Grid,
  IconButton, Menu, MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material";
import { AppColors } from "../../resources/AppColors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH } from "../../resources/ApiUrls";
import { LabelsStudy, menuOptions, SnackBarResponses } from "../../locale/en";
import IconProviderUhda from "../IconProviderUhda";
import Icons from "../../resources/Icons";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import { StorageManager } from "../../utils";
import { SearchBar } from "../SearchBar/SearchBar";
import { useDispatch } from "react-redux";
import { toast } from "../../reducers/notificationsReducer";
import { initializeForm } from "reducers/Form/formActions";

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
  },
  tableHead: {
    backgroundColor: AppColors.CBM_SAND
  },
  tableCell: {
    height: "auto",
    paddingLeft: 0,
    fontSize: "16px",
    color: AppColors.WHITE,

  },
  tableCellInit: {
    padding: 0,
    paddingLeft: "16px",
    borderTopLeftRadius: "5px!important",
    borderBottomLeftRadius: "5px!important",
    borderLeft: "2px solid #B09C78",
    borderTop: "2px solid #B09C78",
    borderBottom: "2px solid #B09C78",
    backgroundColor: AppColors.WHITE,
    color: AppColors.BLACK,
    cursor: "pointer"

  },
  tableCellDraggable: {
    padding: 0,
    paddingRight: 20,
    borderRadius: "2px solid #B09C78",
    borderTop: "2px solid #B09C78",
    borderBottom: "2px solid #B09C78",
    backgroundColor: AppColors.WHITE,
    color: AppColors.BLACK,
    whiteSpace: "normal",
    wordBreak: "break-word",
    cursor: "pointer"
  },
  tableCellDraggableDisabled: {
    padding: 0,
    paddingRight: 20,
    borderRadius: "2px solid #B09C78",
    borderTop: "2px solid #B09C78",
    borderBottom: "2px solid #B09C78",
    backgroundColor: AppColors.WHITE,
    color: AppColors.GREY,
    whiteSpace: "normal",
    wordBreak: "break-word",
    fontStyle: "italic"
  },
  tableCellFinish: {
    padding: 0,
    borderTopRightRadius: "5px!important",
    borderBottomRightRadius: "5px!important",
    borderRight: "2px solid #B09C78",
    borderTop: "2px solid #B09C78",
    borderBottom: "2px solid #B09C78",
    backgroundColor: AppColors.WHITE,
    color: AppColors.PRIMARY,

  },
  // eslint-disable-next-line camelcase
  switch_track: {
    backgroundColor: AppColors.SECONDARY,
  },
  // eslint-disable-next-line camelcase
  switch_base: {
    color: AppColors.SECONDARY,
    "&.Mui-disabled": {
      color: AppColors.PRIMARY_WITH_OPACITY
    },
    "&.Mui-checked": {
      color: AppColors.PRIMARY
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: AppColors.PRIMARY,
    }
  },
  // eslint-disable-next-line camelcase
  switch_primary: {
    "&.Mui-checked": {
      color: AppColors.PRIMARY
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: AppColors.PRIMARY,
    },
  }, visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

}));

const FormListViewUhda = ({
  studyId,
  studyTitle,
  headCells,
  userData,
  loading,
  setLoading,
  handleOnEdit,
  handleOnDuplicate,
  handleOnDelete,
  permissions,
  labels
}) => {
  const [tableData, setTableData] = useState([])
  const [checked, setChecked] = useState(userData)
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [openSnackDuplicate, setOpenSnackDuplicate] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState([]);
  const [searched, setSearched] = useState();
  const history = useHistory()
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const storageManager = new StorageManager()
  const isAdmin = storageManager.getAdmin()
  //const open = Boolean(anchorEl);
  const classes = useStyles();

  const dispatch = useDispatch()

  //Function to handle to sort ascending or descending
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleActionClick = (id, event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget })

  }

  //Function to compare if its descening or ascending
  // eslint-disable-next-line no-shadow
  function descendingComparator(a, b, orderBy) {
    if (orderBy == "title") {
      if (b.form_translations[0][orderBy].toLowerCase() < a.form_translations[0][orderBy].toLowerCase()) {
        return -1;
      }
      if (b.form_translations[0][orderBy].toLowerCase() > a.form_translations[0][orderBy].toLowerCase()) {
        return 1;
      }
    }
    if (orderBy == "tags") {
      if (b.tags.length != 0 && a.tags.length != 0) {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
          return 1;
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
          return -1;
        }

      }
    }
    if (orderBy == "created_by") {
      if (b.user_created != null && a.user_created != null) {
        if (b.user_created.username.toLowerCase() < a.user_created.username.toLowerCase()) {
          return -1;
        }
        if (b.user_created.username.toLowerCase() > a.user_created.username.toLowerCase()) {
          return 1;
        }
      }

    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }

    return 0;
  }

  //Function that return true or false depending on the order
  // eslint-disable-next-line no-shadow
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  //Function to sort the table
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      // eslint-disable-next-line no-shadow
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // eslint-disable-next-line no-unused-vars
  const handleActionClose = (id, event) => {
    delete anchorEl[id]
    setAnchorEl({ ...anchorEl, [id]: null })

  }
  const handleStop = (event, dragElement) => {
    setX(dragElement.x)
    setY(dragElement.y)
  };

  const getData = async () => {
    var response = Object.values(userData)
    var data = []
    response.forEach((res) => {
      data.push(res)
    })
    data.reverse()
    setTableData(data)
  }
  const [studies, setStudies] = useState(Object(userData));

  useEffect(() => {
    getData()

  }, [])

  const handleDelete = async (canDelete, id) => {
    if (isAdmin == 1 || canDelete) {
      handleOnDelete(id)
    } else {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    }

  }
  const handleEdit = async (canEdit, id) => {
    if (isAdmin == 1 || canEdit) {
      handleOnEdit(id.id)
    } else {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    }

  }
  const handleDuplicate = async (canDuplicate, id) => {
    if (isAdmin == 1 || canDuplicate) {
      handleOnDuplicate(id)
    } else {
      setOpenSnackDuplicate(true)
    }

  }

  const handleDragEnd = async (results) => {
    if (!results.destination) return;
    const response = ""
    const tempQuestion = Array.from(studies);
    const [selectedQuestion] = tempQuestion.splice(results.source.index, 1);
    tempQuestion.splice(results.destination.index, 0, selectedQuestion);
    setStudies(tempQuestion);
    var questionsBody = { "questions": [] }
    for (var i = 0; i < tempQuestion.length; i++) {
      questionsBody.questions.push(
        {
          "questionId": tempQuestion[i].id,
          "order": i + 1
        }
      )
    }
    try {
      /* if (isSummary) {
          response = await MyAxiosInstance.put(`${BASE_PATH}/form/${formId}/summary-order`, questionsBody)
          setLoading(true)
          getSummary()
      } else {
          response = await MyAxiosInstance.put(`${BASE_PATH}/form/${formId}/questions`, questionsBody)
          setLoading(true)
          getSections()
      } */
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  }

  const onClickHandler = (formId, formTitle) => {
    localStorage.setItem("formRoute", "edit")
    const newObj = { "detail": studyId, "formId": formId, "permissions": labels }
    localStorage.setItem("formDetails", JSON.stringify(newObj));
    dispatch(initializeForm(studyId, formId))

    history.push({
      pathname: `/study/${studyId}/form/${formId}`, //TODO cambiar porque es de pruebas para el study
      state: {
        studyId,
        studyTitle,
        formId,
        formTitle,
        value: 6,
        permissions: labels,
        studyPermissions: permissions,
      }

    })
  }

  //Function to close the snackbar
  const knowPermissions = (formId, formTitle) => {
    const label = [];
    const link = [];
    if (isAdmin == 1 || permissions.includes("access-data")) {

      label.push(LabelsStudy.DASHBOARD)
      //link.push(`/study/${studyId}/dashboard`)
    }
    if (isAdmin == 1 || permissions.includes("forms-view")) {
      label.push(LabelsStudy.FORMS)
      //link.push(`/study/${studyId}/forms`)
    }
    if (isAdmin == 1 || permissions.includes("participant-create") || permissions.includes("participant-edit") || permissions.includes("participant-delete")) {
      label.push(LabelsStudy.PARTICIPANTS)
      //link.push(`/study/${studyId}/participants`)
    }
    if (isAdmin == 1 || permissions.includes("access-dissemination")) {
      label.push(LabelsStudy.DISSEMINATION)
      //link.push(`/study/${studyId}/dissemination`)
    }
    if (isAdmin == 1 || permissions.includes("teammember-create") || permissions.includes("teammember-edit") || permissions.includes("teammember-delete")) {
      label.push(LabelsStudy.TEAM_MEMBERS)
      //link.push(`/study/${studyId}/teamMembers`)
    }
    if (isAdmin == 1 || permissions.includes("study-edit-settings")) {
      label.push(LabelsStudy.SETTINGS)
      //link.push(`/study/${studyId}/settings`)
    }
    onClickHandler(formId, formTitle)
    //setDrawerLink(link)
  };

  return <>
    <TableContainer>
      <SearchBar
        entities={userData}
        setFilteredValues={setTableData}
        placeholder={"Search by title"}
        searchKey={"title"}
      />
      <Table className={classes.table} aria-label={"simple table"} >
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell style={{ color: AppColors.WHITE, fontWeight: "bold" }} key={headCell.id}
                align={headCell.disablePadding ? "right" : "left"} padding="normal"
                sortDirection={orderBy === headCell.id ? order : false}>
                {headCell.isSortable ?
                  <TableSortLabel style={{ color: AppColors.WHITE }} active={true}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}>
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel> :
                  <>{headCell.label}</>
                }
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody
          className={classes.tableCell}>
          {(studies && !loading) && stableSort(tableData, getComparator(order, orderBy)).map((form, index) => (
            <TableRow key={form.id}
            >
              <TableCell className={classes.tableCellInit} style={{ width: 10 }}>

              </TableCell>
              {/* <TableCell style={{ width: 24 }}
                        className={classes.tableCellDraggable}>{form.id}</TableCell> */}
              <TableCell data-testId={"formRow"} onClick={() => onClickHandler(form.id, form.form_translations[0].title)}
                className={classes.tableCellDraggable}>{form.form_translations[0].title}</TableCell>
              <TableCell onClick={() => onClickHandler(form.id, form.form_translations[0].title)}
                className={classes.tableCellDraggable}>{form.form_translations[0].description}</TableCell>
              <TableCell onClick={() => knowPermissions(form.id, form.form_translations[0].title)}
                className={classes.tableCellDraggable}>{form.tags != "" ? form.tags : "-"}</TableCell>
              <TableCell onClick={() => knowPermissions(form.id, form.form_translations[0].title)}
                className={classes.tableCellDraggable}>{form.user_created ? form.user_created.username : "-"}</TableCell>
              <TableCell onClick={() => onClickHandler(form.id, form.form_translations[0].title)}
                className={classes.tableCellDraggable}>{Moment(form.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>

              <TableCell className={classes.tableCellFinish} align='left'>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    data-testId='menuButton'
                    onClick={e => handleActionClick(form.id, e)}>
                    <IconProviderUhda icon={<Icons.OPTIONS_HORIZONTAL
                      color={AppColors.PRIMARY} style={{
                        verticalAlign: "middle",
                        display: "inline-flex",
                        paddingRight: "4px"
                      }} size="2rem" />} />
                  </Button>
                  <Menu
                    style={{
                      boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)"
                    }}
                    color={AppColors.WHITE}
                    id={form.id}
                    anchorEl={anchorEl[form.id]}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    keepMounted
                    open={Boolean(anchorEl[form.id])}
                    onClose={e => handleActionClose(form.id, e)}
                    TransitionComponent={Fade}>
                    {(isAdmin == 1 || permissions.includes("form-edit")) && (
                      <MenuItem
                        style={{ color: AppColors.PRIMARY }}
                        onClick={() => {
                          handleEdit(permissions.includes("form-edit"), form);
                        }}> {menuOptions.EDIT} </MenuItem>)}
                    {(isAdmin == 1 || permissions.includes("form-duplicate")) && (
                      <MenuItem
                        style={{ color: AppColors.PRIMARY }}
                        data-testId='duplicateOption'
                        onClick={() => {
                          handleDuplicate(permissions.includes("form-duplicate"), form);
                        }}> {menuOptions.DUPLICATE} </MenuItem>)}
                    {(isAdmin == 1 || permissions.includes("form-delete")) &&
                      <MenuItem
                        style={{ color: AppColors.PRIMARY }}
                        data-testId="deleteOption"
                        onClick={() => {
                          handleDelete(permissions.includes("form-delete"), form);
                        }}> {menuOptions.DELETE}</MenuItem>}
                  </Menu>
                </Grid>

              </TableCell>
            </TableRow>
          ))}
        </TableBody>

      </Table>

      {studies.length === 0 &&
        <Grid
          style={{ height: "200px" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>
            Nothing to see here yet...
          </Typography>
        </Grid>
      }

    </TableContainer>

    <Grid container justifyContent={"flex-end"} style={{ paddingRight: "1.5em" }}>
      <Typography style={{ color: AppColors.PRIMARY }}>
        Total: {studies.length} surveys
      </Typography>
    </Grid>
  </>;

}

export default FormListViewUhda
