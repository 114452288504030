/* eslint-disable no-shadow */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { AppColors } from "../../resources/AppColors"
import DialogUhda from "../DialogUhda"
import { CommonTexts } from "../../locale/en"
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import TextUhda from "../TextUdha";
import { textType } from "../../resources/AppTexts";
import makeStyles from '@mui/styles/makeStyles';

import Icons from "../../resources/Icons";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH } from "../../resources/ApiUrls";

import { format, parseISO, isBefore, isAfter } from 'date-fns';
import { utils, writeFileXLSX } from 'xlsx';
import DatePickerUhda from "../DatePickerUhda/DatePickerUhda"


// const ExcelFile = ExportExcel.ExcelFile;
// const ExcelSheet = ExportExcel.ExcelSheet
// const ExcelColumn = ExportExcel.ExcelColumn
const useStyles = makeStyles((theme) => ({
  title: {
    font: "Roboto",
    color: AppColors.PRIMARY
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,

  }, textFieldLabelDisabled: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.SUBTEXT,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      borderRadius: 10,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.PIRMARY_WITH_OPACITY,
    borderRadius: 10,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,

      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      backgroundColor: "transparent"

    },
    "&:before": {
      color: AppColors.WHITE,
    },
    "&:after": {
      borderBottomColor: AppColors.WHITE,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.WHITE,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },
  select1: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,

      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      backgroundColor: "transparent"

    },
    "&:before": {
      color: AppColors.WHITE,
    },
    "&:after": {
      borderBottomColor: AppColors.WHITE,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.WHITE,
    borderRadius: 20,
  },

}));

/**
 * @component
 * Component to add a new question for the form
 *
 * @param {function} handleClickNext: handle to show the next modal
 * @param {object} initialValues: array of dictionaries of the initial values
 * @param {object} values: values that will change with the new initial values
 * @param {function} setValues: values that will change
 * @param {boolean} showAddQuestion: state to show or not the new question
 * @param {function} setShowAddQuestion: function to set if the add question will be showed
 * @param {string} formId: id of the form
 * @param {string} studyId: id of the study
 * @param {function} changeMandatory: function to change the values of the mandatory
 * @param {function} changeConditional: function to change the values of the conditional
 * @param {boolean} hasNextModal: value to know if the questionary have more questions
 * @param {function} setHasNextModal: state to change when will be next question.
 *
 * @constructor
 * <AddQuestionModal hasNextModal={hasNextModal} setHasNextModal={setHasNextModal} formId={formId} studyId={studyId} handleClickNext={handleClickNext}
 * initialValues={initialValues} values={values} setValues={setValues} showAddQuestion={showAddQuestion} setShowAddQuestion={setShowAddQuestion}
 * changeConditional={changeConditional} changeMandatory={changeMandatory}
 />
 */
export function DownloadModal({
  answersData,
  questionsNames,
  initialValues,
  values,
  setValues,
  showDownload,
  setShowDownload,
  formId,
  studyId,
  minDate,
}) {
  const [initialDate, setInitialDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // * If value is 0 it sets export data type as value, if 1 is set as label
  const [typeData, setTypeData] = useState(1)
  const [form, setForm] = useState()

  const classes = useStyles()

  //Function that change the type of the data
  const handleChange = (event) => {
    setTypeData(event.target.value);
  };

  useEffect(() => {
    setValues(initialValues)
    setValues({ ...values, ["form"]: formId })
    getForm()
  }, [showDownload])

  /**
   * It gets the form from the API.
   */
  const getForm = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${studyId}/form/${formId}`);
      setForm(response.data.data.item)
    } catch (err) {
      console.error(err)
      setForm(err.message)
    }
  }

  function handleInitialDate(e) {
    setInitialDate(e);
  }

  function handleEndDate(e) {
    setEndDate(e)
  }

  /*
   * It takes the data from the API and creates an array of objects with the data that will be used to
   * create the excel file
   * 
   *  1 - Formats the data from the table to a xlsx valid format
   *  2 - If a date range is selected (it is possible to use only start or end date) it filters out the data outside of the range
   *  3 - User can select two types of exports labels and values
  */

  function handleExportingData() {
    const formatDataForExport = answersData.reduce((allData, currentDataPoint) => {
      const dataPointDate = new Date(format(parseISO(currentDataPoint.updated_at), "yyyy-MM-dd"))
      const selectedInitialDate = initialDate && new Date(format(initialDate, "yyyy-MM-dd"))
      const selectedEndDate = endDate && new Date(format(endDate, "yyyy-MM-dd"))

      if (initialDate && isBefore(dataPointDate, selectedInitialDate)) return allData

      if (endDate && isAfter(dataPointDate, selectedEndDate)) return allData

      // * If the user didn't replied to all answers, add them to the data point to prevent columns order bugs and to display - in the cell
      const missingResponses = questionsNames.filter(question => {
        const { name } = question
        const { answers } = currentDataPoint

        const answer = answers.find(answer => {
          if (!answer.question) return true

          return answer.question?.variable_name === name
        })
        return !answer
      })

      if (missingResponses.length > 0) {
        const missingResponsesWithEmptyValues = missingResponses.map(missingResponse => {
          return {
            question: {
              variable_name: missingResponse.name,
              section_id: missingResponse.sectionId,
              order: missingResponse.order
            },
            data: "-",
            data_label: "-"
          }
        })
        currentDataPoint = { ...currentDataPoint, answers: [...currentDataPoint.answers, ...missingResponsesWithEmptyValues] }
      }

      const formattedAnswers = currentDataPoint.answers.reduce((allAnswers, answer) => {
        const { question, data, data_label } = answer

        if (!question) return allAnswers

        const answerData = typeData === 0 ? data?.toString() : data_label?.toString()

        return [
          ...allAnswers,
          {
            answerData,
            variable_name: question.variable_name,
            sectionId: question.section_id,
            order: question.order
          }
        ]
      }, [])

      const sortedAnswers = formattedAnswers
        .sort((a, b) => {
          if (a.sectionId !== b.sectionId) {
            return a.sectionId - b.sectionId;
          }
          return a.order - b.order;
        })
        .reduce((result, question) => {
          return { ...result, [question.variable_name]: question.answerData }
        }, {});

      const extraAnswerFields = {
        id: currentDataPoint.id,
        participant: !!currentDataPoint.participant ? currentDataPoint.participant.uuid : "Anonymous",
        submitted_at: format(new Date(currentDataPoint.updated_at), "dd-MM-yyyy HH:mm"),
        user: !!currentDataPoint.user ? currentDataPoint.user.username : "Anonymous",
      }

      const finalAnswers = { ...extraAnswerFields, ...sortedAnswers }

      return [...allData, finalAnswers]
    }, [])

    formatDataForExport.reverse()

    const worksheet = utils.json_to_sheet(formatDataForExport);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "answers");

    const currentDate = format(new Date(), 'dd/MM/yyyy');

    const fileName = `${form?.form_translations[0]?.title}_${typeData === 0 ? "values" : "labels"}_${currentDate}.xlsx`

    writeFileXLSX(workbook, fileName, { compression: true })
  }

  return (
    <DialogUhda
      handleModalClose={() => {
        setValues(initialValues)
        setShowDownload(-999)
      }}
      size={false}
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.DOWNLOAD}
      handleShow={setShowDownload}
      handleConfirm={(typeData === 1 || typeData === 0) && handleExportingData}
      title={CommonTexts.DOWNLOAD_FORM}
      body={
        <Grid
          style={{ paddingLeft: "30px" }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item style={{ paddingTop: "30px" }}>
            <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
              text={"Between dates "} />
          </Grid>
          <FormControl

            className={classes.select}
            margin='normal'
            style={{ width: "152px" }}>
            <DatePickerUhda
              value={initialDate}
              setValue={handleInitialDate}
              label={"From"}
              minDate={parseISO(minDate)}
              maxDate={new Date()}
            />
          </FormControl>
          <FormControl

            className={classes.select}
            margin='normal'
            style={{ width: "152px" }}>
            <DatePickerUhda
              value={endDate}
              setValue={handleEndDate}
              label={"Until"}
              minDate={initialDate ? initialDate : parseISO(minDate)}
              maxDate={new Date()}
            />
          </FormControl>

          <Grid
            container spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item style={{ marginRight: "3.8em", paddingTop: "45px" }}>
              <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                text={"Type of data"} />
            </Grid>
            <Grid item>
              <FormControl
                className={classes.select1}
                variant="outlined"
                margin='normal'
                style={{ minWidth: "145px" }}
              >
                <InputLabel>Types</InputLabel>
                <Select

                  value={typeData}
                  onChange={handleChange}
                  label="Types"
                  style={{ width: "360px", backgroundColor: AppColors.CBM_SAND_OPACITY, borderRadius: 10 }}
                  IconComponent={Icons.ARROW_DOWN}>
                  <MenuItem style={{ color: AppColors.PRIMARY }}
                    value={1}>{CommonTexts.LABELS}</MenuItem>
                  <MenuItem style={{ color: AppColors.PRIMARY }}
                    value={0}>{CommonTexts.VALUES}</MenuItem>

                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {!form &&
            (<Grid container justifyContent={"center"}>
              <CircularProgress color={"#C4141B"} />
            </Grid>)
          }
        </Grid>
      }
      show={showDownload}
    />
  );
}
DownloadModal.propTypes = {
  formId: PropTypes.string.isRequired,
  studyId: PropTypes.string.isRequired,
}
